import $ from 'jquery';
import { Datepicker } from 'vanillajs-datepicker';
import 'eonasdan-bootstrap-datetimepicker';
import { OBSERVER } from '../formbuilder';
import AddressGoogle from './addressGoogle';
import isMobile from './helper';

// Ajoute un attribut ayant la valeur du champ
export function inputsAndTextareaLabel(classeContainerFormBuilder) {
  // eslint-disable-next-line max-len
  const elementsString = `${classeContainerFormBuilder} input[type="text"],${classeContainerFormBuilder} input[type="tel"],${classeContainerFormBuilder} input[type="email"],${classeContainerFormBuilder} textarea`;
  const formElements = document.querySelectorAll(elementsString);
  const formElementsLength = formElements.length;
  let i;

  for (i = 0; i < formElementsLength; i += 1) {
    formElements[i].parentNode.parentNode.dataset[`${formElements[i].tagName.toLowerCase()}value`] = formElements[i].value;
  }

  const setValue = (e) => {
    e.target.parentNode.parentNode.dataset[`${e.target.tagName.toLowerCase()}value`] = e.target.value;
  };

  OBSERVER.add({
    name: 'inputsAndTextareaLabel',
    event: 'input',
    target: elementsString,
    function: setValue,
  });

  OBSERVER.on('inputsAndTextareaLabel');
}

// Modification de la hauteur d'un textarea selon son contenu
export function textareaHeight(classeContainerFormBuilder) {
  const onInput = (e) => {
    e.currentTarget.style.height = '5px';
    e.currentTarget.style.height = `${e.currentTarget.scrollHeight <= 43 ? 43 : e.currentTarget.scrollHeight}px`;
  };

  OBSERVER.add({
    name: 'textareaHeight',
    event: 'input',
    target: `${classeContainerFormBuilder} textarea`,
    function: onInput,
  });

  OBSERVER.on('textareaHeight');
}

// Création du custom select (doc: https://github.com/pytesNET/tail.select)
export function tailSelect(classeContainerFormBuilder) {
  const pathImagesIcons = '/plugins/blanko/forms/assets/images/icons/';

  if (!isMobile()) {
    // eslint-disable-next-line no-undef
    tail.select(`${classeContainerFormBuilder} select`, { animate: false });
  }

  // Ajouter les images dans les options du Tail Select
  let i;
  let j;
  const selectBoxes = document.querySelectorAll(`${classeContainerFormBuilder} .tail-select`);
  const selectBoxesLength = selectBoxes.length;

  // Looper dans chaques boites de sélections Tail Select
  for (i = 0; i < selectBoxesLength; i += 1) {
    const tailSelectItems = selectBoxes[i].querySelectorAll('.dropdown-option');
    const nativeSelect = selectBoxes[i].previousElementSibling;
    const nativeSelectItems = nativeSelect.querySelectorAll('option:not(:first-child)');

    // Ajouter l'icone en symbole
    const svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    const useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use');
    useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `${pathImagesIcons}symbols.svg#ico-pointer`);
    svgElem.appendChild(useElem);
    selectBoxes[i].appendChild(svgElem);

    // Looper dans chaques item du Tail Select
    for (j = 0; j < nativeSelectItems.length; j += 1) {
      const imgPath = nativeSelectItems[j].dataset.image;
      if ((typeof imgPath !== 'undefined') && imgPath !== '') {
        const newImage = document.createElement('img');
        newImage.src = imgPath;
        tailSelectItems[j].classList.add('has-image');
        tailSelectItems[j].appendChild(newImage);
      }
    }
  }
}

// Initialisation du champ de timepicker
export function timepicker(classeContainerFormBuilder) {
  if (!isMobile()) {
    $(`${classeContainerFormBuilder} .form-control.timepicker`).datetimepicker({
      format: 'HH:mm',
      icons: {
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
      },
    });
  }
}

// Initialisation du champ de calendrier
export function calendarInput(classeContainerFormBuilder) {
  // Si on est pas en mobile, mettre le calendrier en JS
  if (!isMobile()) {
    const datepickersWrapper = document.querySelectorAll(`${classeContainerFormBuilder} .js-datepickerWrapper`);
    const datepickers = [];

    Datepicker.locales.fr = {
      days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
      months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
      monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
      today: 'Aujourd\'hui',
      monthsTitle: 'Mois',
      clear: 'Effacer',
      weekStart: 0,
      format: 'dd/mm/yyyy',
    };

    for (let i = 0; i < datepickersWrapper.length; i += 1) {
      datepickers.push(
        new Datepicker(datepickersWrapper[i], {
          language: 'fr',
          prevArrow: '<svg><use xlink:href="/plugins/blanko/forms/assets/images/icons/symbols.svg#ico-pointer"></use></svg>',
          nextArrow: '<svg><use xlink:href="/plugins/blanko/forms/assets/images/icons/symbols.svg#ico-pointer"></use></svg>',
          format: 'dd MM yyyy',
          todayHighlight: true,
        }),
      );
    }

    const onFocus = (e) => {
      const datepicker = e.target.parentElement.parentElement.querySelector(`${classeContainerFormBuilder} .js-datepickerWrapper`);
      datepicker.classList.add('show');
    };

    const onChangeDate = (e) => {
      for (let i = 0; i < datepickersWrapper.length; i += 1) {
        if (e.currentTarget === datepickers[i].element) {
          e.currentTarget.parentElement.querySelector('input').value = datepickers[i].getDate('dd MM yyyy');
          e.currentTarget.classList.remove('show');
        }
      }
    };

    const closeCalendar = () => {
      for (let i = 0; i < datepickersWrapper.length; i += 1) {
        datepickersWrapper[i].classList.remove('show');
      }
    };

    const preventClose = (e) => {
      e.stopPropagation();
    };

    OBSERVER.add({
      name: 'input-calendar',
      event: 'focus',
      target: `${classeContainerFormBuilder} .js-input-calendar input`,
      function: onFocus,
    });
    OBSERVER.add({
      name: 'input-calendar',
      event: 'click',
      target: `${classeContainerFormBuilder} .js-input-calendar input`,
      function: onFocus,
    });
    OBSERVER.add({
      name: 'input-calendar',
      event: 'changeDate',
      target: `${classeContainerFormBuilder} .js-datepickerWrapper`,
      function: onChangeDate,
    });
    OBSERVER.add({
      name: 'input-calendar',
      event: 'click',
      function: closeCalendar,
    });
    OBSERVER.add({
      name: 'input-calendar',
      event: 'click',
      target: `${classeContainerFormBuilder} .js-input-calendar`,
      function: preventClose,
    });
    OBSERVER.on('input-calendar');

    // Si on est en mobile, utiliser les calendriers en HTML5
  } else {
    document.querySelectorAll(`${classeContainerFormBuilder} .js-input-calendar input`).forEach(
      (element) => element.setAttribute('type', 'date'),
    );
  }
}

// Google Autocomplete pour le champ Adresse
function addressAutocomplete(classeContainerFormBuilder) {
  const adressFields = $(`${classeContainerFormBuilder} .localisation_field`).get();
  adressFields.forEach((adressField) => {
    // eslint-disable-next-line no-new
    new AddressGoogle(adressField);
  });
}

// Le package à appliquer pour les formulaires
export function formsPackage(classeContainerFormBuilder) {
  inputsAndTextareaLabel(classeContainerFormBuilder);
  textareaHeight(classeContainerFormBuilder);
  // tailSelect(classeContainerFormBuilder);  // Déjà appelé dans le theme
  timepicker(classeContainerFormBuilder);
  // calendarInput(classeContainerFormBuilder);  // Déjà appelé dans le theme
  addressAutocomplete(classeContainerFormBuilder);
}
