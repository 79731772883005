import Observer from './vendor/observer.min';
import { formsPackage } from './functions/form';
import masksPackage from './functions/masks';
import setConditions from './functions/conditions';
import repeaterButtons from './functions/repeaters';


export const OBSERVER = Observer.getInstance();

addEventListener('DOMContentLoaded', () => {
  initFormBuilder();
})


export const initFormBuilder = (withRecaptcha = true) => {
  const classeContainerFormBuilder = '.dynamicForm';
  formsPackage(classeContainerFormBuilder);
  masksPackage();
  initFileUpload(classeContainerFormBuilder);
  setConditions();
  repeaterButtons();

  if (withRecaptcha) {
    recaptchaFormsCallback();
  }
};

window.initFormBuilder = initFormBuilder;
